import React from 'react';
import Sidebar from './Sidebar';
import DashboardContent from './DashboardContent';

function RecruiterDashboard() {
  return (
    <div className="flex">
      <Sidebar />
      <DashboardContent />
    </div>
  );
}

export default RecruiterDashboard;
