import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function Sidebar({ role }) {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login'); // Redirect to login after logout
  };

  return (
    <div className="bg-gray-800 text-white w-64 h-screen flex flex-col">
      <div className="flex items-center justify-center h-16 border-b border-gray-700">
        <h1 className="text-2xl font-bold">{role === 'superadmin' ? 'Super Admin Dashboard' : 'Recruiter Dashboard'}</h1>
      </div>
      <nav className="flex-1">
        <ul className="mt-10">
          <li>
            <Link to={role === 'superadmin' ? "/dashboard/superadmin" : "/dashboard/recruiter"} className="block px-4 py-2 hover:bg-gray-700">Home</Link>
          </li>
          {role === 'superadmin' && (
            <>
              <li>
                <Link to="/manage-users" className="block px-4 py-2 hover:bg-gray-700">Manage Users</Link>
              </li>
              <li>
                <Link to="/reports" className="block px-4 py-2 hover:bg-gray-700">Reports</Link>
              </li>
            </>
          )}
          {role === 'recruiter' && (
            <>
              <li>
                <Link to="/candidates" className="block px-4 py-2 hover:bg-gray-700">Candidates</Link>
              </li>
              <li>
                <Link to="/job-postings" className="block px-4 py-2 hover:bg-gray-700">Job Postings</Link>
              </li>
            </>
          )}
          <li>
            <button onClick={handleLogout} className="block w-full px-4 py-2 text-left hover:bg-gray-700">Logout</button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
