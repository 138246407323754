import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import RecruiterDashboard from './components/RecruiterDashboard';
import SuperAdminDashboard from './components/SuperAdminDashboard';
import Login from './components/auth/Login';
import Register from './components/auth/Register';

const PrivateRoute = ({ element, role }) => {
  const { user } = useAuth();
  return user && user.user.role === role ? element : <Navigate to="/login" />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard/recruiter" element={<PrivateRoute element={<RecruiterDashboard />} role="recruiter" />} />
          <Route path="/dashboard/superadmin" element={<PrivateRoute element={<SuperAdminDashboard />} role="superadmin" />} />
          {/* Add other routes for candidates, job postings, etc. */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
