import React from 'react';
import Sidebar from './Sidebar';
import DashboardContent from './DashboardContent';

function SuperAdminDashboard() {
  return (
    <div className="flex">
      <Sidebar role="superadmin" /> {/* Pass role to Sidebar if needed */}
      <div className="flex-1 p-6 bg-gray-100">
        <h2 className="text-3xl font-bold mb-4">Welcome to the Super Admin Dashboard!</h2>
        <p className="mb-2">Here you can manage users, view reports, and oversee operations.</p>
        {/* Add more sections as necessary */}
      </div>
    </div>
  );
}

export default SuperAdminDashboard;
