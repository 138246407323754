import React from 'react';

function DashboardContent() {
  return (
    <div className="flex-1 p-6 bg-gray-100">
      <h2 className="text-3xl font-bold mb-4">Welcome to the Recruiter Dashboard!</h2>
      <p className="mb-2">Here you can manage candidates, job postings, and applications.</p>
      
      {/* Add more sections as necessary */}
      <div className="mt-6 bg-white p-4 rounded shadow">
        <h3 className="text-xl font-semibold">Your Recent Activity</h3>
        {/* Placeholder for recent activity */}
        <ul className="mt-2">
          <li>Posted a new job: Software Engineer</li>
          <li>Reviewed 5 applications</li>
          <li>Contacted 3 candidates</li>
        </ul>
      </div>
    </div>
  );
}

export default DashboardContent;
